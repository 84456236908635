import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const Divider = () => {
    return <hr style={styles.divider} />;
  };

  return (
    <footer style={styles.footer}>
      <Container>
        <Row></Row>
        <Row>
          <Col>
            <p style={styles.howHelp}>How can we help?</p>
            <Button
              variant="primary"
              href="/contact"
              style={{ width: 160, height: 40 }}
            >
              Plan your project
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col></Col>
          <Col className="d-flex justify-content-end">
            <p style={{ textAlign: "right" }}>
              Cross Vision Development Studios | ACJ Operations, LLC | Copyright
              2023
            </p>
          </Col>
        </Row>
      </Container>
      {/* <div style={styles.bgView}></div> */}
    </footer>
  );
};

export default Footer;

const styles = {
  // bgView: {
  //   position: "relative",
  //   zIndex: 0,
  //   left: 0,
  //   bottom: 0,
  //   height: 140,
  //   width: 500,
  //   backgroundImage: `url(${bgImage})`,
  //   backgroundSize: "cover",
  // },
  divider: {
    color: "lightGrey",
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  footer: {
    background: "#1C1816",
    color: "#FFF",
    paddingTop: 20,
    minHeight: 80,
  },
  howHelp: {
    fontSize: 30,
  },
};
