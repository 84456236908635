import React from "react";
import { Container } from "react-bootstrap";

//Background Images
import mainBackground from "../../assets/backgrounds/mountains.png";

const MainBody = (props) => {
  return (
    <Container fluid style={styles.mainBodyStyle}>
      {props.children}
    </Container>
  );
};

export default MainBody;

const styles = {
  mainBodyStyle: {
    height: "100%",
    paddingTop: 150,
    paddingBottom: 150,
    backgroundImage: `url(${mainBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    verticalAlign: "middle",
  },
};
