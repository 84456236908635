import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";

const AppNavBar = () => {
  const cvdsIcon = require("../assets/logos/cvdsLogoCropped.png");
  return (
    <Navbar
      className="navbar-nav navbar-dark"
      collapseOnSelect
      expand="lg"
      style={styles.navBar}
    >
      <Container>
        <Navbar.Brand href="/">
          <Image
            src={cvdsIcon}
            height="20"
            width="100"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav navbar-light">
          <Nav className="ms-auto">
            <Nav.Link className="nav-links" href="/" style={styles.link}>
              Home
            </Nav.Link>
            <Nav.Link className="nav-links" href="/about" style={styles.link}>
              About
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href="/services"
              style={styles.link}
            >
              Services
            </Nav.Link>
            <Nav.Link className="nav-links" href="/portfolio" style={styles.link}>
              Portfolio
            </Nav.Link>
            <Nav.Link className="nav-links" href="/contact" style={styles.link}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavBar;

const styles = {
  link: {
    color: "#FFF",
    fontWeight: 600,
    fontSize: 16,
    textDecoration: "none",
  },
  navBar: {
    backgroundColor: "#1C1816",
    minHeight: 60,
  },
};
