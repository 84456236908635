import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Services = () => {
  const data = [
    {
      id: 0,
      name: "Mobile App Development",
      iconName: "fa-mobile-screen-button",
      color: "red",
    },
    {
      id: 1,
      name: "Web Development",
      iconName: "fa-solid fa-laptop",
      color: "grey",
    },
    {
      id: 2,
      name: "Web Hosting",
      iconName: "fa-regular fa-hard-drive",
      color: "#FFF",
    },
  ];

  // Sections
  const CardSection = () => {
    const cards = data.map((item) => {
      return (
        <Col key={item.id} md={4}>
          <Card style={styles.card}>
            <Card.Body>
              <FontAwesomeIcon
                icon={item.iconName}
                size="4x"
                style={{ ...styles.icon, ...{ color: item.color } }}
              />
              <Card.Text style={{ fontSize: 22, fontWeight: 600 }}>
                {item.name}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    return (
      <Container>
        <Row style={{ marginBottom: 200 }}>{cards}</Row>
      </Container>
    );
  };

  const BackendSection = () => {
    return (
      <Container style={styles.sectionRow}>
        <Row>
          <h3 style={styles.title}>Backend Development Services</h3>
        </Row>
        <Row>
          <p style={{textAlign: "center"}}>We work with:</p>
        </Row>
        <Row>
          <Col className="d-flex justify-content-evenly">
            <ul style={styles.bullets}>
              <li>APIs</li>
              <li>Redux</li>
              <li>Node.js</li>
              <li>Firebase</li>
            </ul>
            <ul style={styles.bullets}>
              <li>PHP</li>
              <li>Java</li>
              <li>MongoDB</li>
              <li>MySQL/MariaDB</li>
            </ul>
          </Col>
        </Row>
      </Container>
    );
  };

  const FrontendSection = () => {
    return (
      <Container style={styles.sectionRow}>
        <Row className="mb-3">
          <h3 style={styles.title}>Frontend Development Services</h3>
        </Row>
        <Row>
          <p style={{textAlign: "center"}}>We work with:</p>
        </Row>
        <Row>
          <Col className="d-flex justify-content-evenly">
            <ul style={styles.bullets}>
              <li>React</li>
              <li>Redux</li>
              <li>ES6+</li>
            </ul>
            <ul style={styles.bullets}>
              <li>React Native</li>
              <li>Swift</li>
              <li>Objective-C</li>
              <li>Java</li>
            </ul>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <Container fluid style={styles.container}>
        <Row style={{ marginBottom: 100 }}>
          <h3 style={styles.title}>
            We work with our clients to create affordable world-class software.
          </h3>
        </Row>
        <CardSection />
        <FrontendSection />
        <BackendSection />
      </Container>
    </>
  );
};

export default Services;

const styles = {
  bullets: {
    color: "#FFF",
    textAlign: "left",
    fontSize: 24,
  },
  card: {
    backgroundColor: "#000",
    color: "#FFF",
  },
  container: {
    backgroundColor: "#000",
    paddingTop: 150,
    paddingBottom: 150,
  },
  divider: {
    color: "lightGrey",
    height: 2,
    marginTop: 5,
    marginBottom: 5,
  },
  icon: {
    color: "#FFF",
    marginBottom: 20,
  },
  sectionRow: {
    marginBottom: 200,
    textAlign: "left",
    color: "#FFF",
    fontSize: 20,
  },
  title: {
    color: "#FFF",
    fontSize: 44,
    textAlign: "center"
  },
};
