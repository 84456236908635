import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainBody from "../components/layout/MainBody";
import TeamSection from "./TeamSection";

const About = () => {
  const topDescription =
    "Cross Vision Development Studios offers software engineering for all of your custom software needs. Our engineers have a combined 40 years experience designing, building, deploying and maintaining custom applications and software solutions. Our engineers can develop your application for Web, Mobile or Desktop use, deploy your software securely and implement any feature set your project needs. Our engineers can build your application from start to end, or they can add features to an existing piece of software. We have expertise working with JavaScript, React, PHP, Swift, Java, C#, Python, Flask and many more languages, frameworks and technology stacks.";

  const Divider = () => {
    return <hr style={styles.divider} />;
  };

  return (
    <>
      <MainBody>
        <Row className="mb-5">
          <h1 style={styles.title}>Design, Build, Deploy &amp; Maintain</h1>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <p style={styles.description}>{topDescription}</p>
          </Col>
        </Row>
      </MainBody>
      <Container fluid style={styles.container}>
        <Row className="mb-3">
          <h3 style={styles.title}>Have a great idea for an application?</h3>
        </Row>
        <Row>
          <h3 style={styles.title}>We can build it for you.</h3>
        </Row>
        <Divider />
        <TeamSection />
      </Container>
    </>
    //
  );
};

export default About;

const styles = {
  container: {
    backgroundColor: "#000",
    paddingTop: 150,
    paddingBottom: 150,
  },
  description: {
    color: "#FFF",
    width: 800,
    fontSize: 24,
    fontWeight: 500,
  },
  divider: {
    color: "lightGrey",
    height: 2,
    marginTop: 100,
    marginBottom: 100,
  },
  title: {
    color: "#FFF",
  },
};
