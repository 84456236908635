import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { isBrowser } from "react-device-detect";

const data = [
  {
    backgroundImageUrl: require("../assets/images/company/adrian_headshot.jpeg"),
    name: "Adrian C. Johnson",
    role: "Sr. Software Engineer",
  },
  {
    backgroundImageUrl: require("../assets/images/company/donnis_headshot.jpeg"),
    name: "Donnis Jones",
    role: "Sr. Software Engineer",
  },
  {
    backgroundImageUrl: require("../assets/images/company/jefftan_headshot.jpeg"),
    name: "Jeff Tan",
    role: "Product Manager",
  },
  {
    backgroundImageUrl: require("../assets/images/company/worth_headshot.jpeg"),
    name: "Worth Winchester",
    role: "Jr. Software Engineer",
  },
];

const TeamSection = () => {
  const teamCards = data.map((content) => {
    return (
      <Col
        className="content"
        key={content.id}
        xs={12}
        md={3}
        style={styles.item}
      >
        <Card
          style={{
            ...styles.card,
            ...{ backgroundImage: `url('${content.backgroundImageUrl}')` },
          }}
        >
          <Card.Body className={"content-overlay"}>
            <Col style={styles.stack}>
              <Row></Row>
              <Row>
                <Col>
                  <Row style={styles.infoRow}>
                    <p style={styles.name}>{content.name}</p>
                  </Row>
                  <Row style={styles.infoRow}>
                    <p style={styles.role}>{content.role}</p>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  return (
    <Container>
      <Col className="my-5">
        <Row>
          <h3 style={styles.title}>Our Corporate Team</h3>
        </Row>
        <Row>{teamCards}</Row>
      </Col>
    </Container>
  );
};

export default TeamSection;

const styles = {
  card: {
    height: 240,
    padding: isBrowser ? 20 : 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "transparent",
  },
  infoRow: {
    textAlign: "center",
  },
  item: {
    padding: isBrowser ? 12 : 5,
  },
  name: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 0,
  },
  role: {
    color: "#CF3620",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 0,
  },
  stack: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 0,
  },
  title: {
    color: "#FFF",
    fontSize: 44,
  },
};
