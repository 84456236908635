import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";

const Portfolio = () => {
  const a1pLogo = require("../assets/clients/a1p/a1p_logo.png");
  const jgpgLogo = require("../assets/clients/jgpg/jgpg_logo.png");
  const rhrLogo = require("../assets/clients/rhr/rhr_logo.png");
  const vguLogo = require("../assets/clients/vgu/vgu_logo.png");
  const vvp_1 = require("../assets/clients/vgu/vvp_1.png");
  const vvp_2 = require("../assets/clients/vgu/vvp_2.png");
  const vvp_3 = require("../assets/clients/vgu/vvp_3.png");
  const vipvegasplus_promo_video = require("../assets/clients/vgu/vvp_promo.mp4");
  const appStoreImage = require("../assets/mobile/getappstore.png");
  const playStoreImage = require("../assets/mobile/getgoogleplay.png");

  const Divider = () => {
    return <hr style={styles.divider} />;
  };

  return (
    <>
      <Container fluid style={styles.container}>
        <Container>
          <Row className="pb-5">
            <Col xs={12} md={6}>
              <Row className="mb-4">
                <h2 style={styles.title}>App Development</h2>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col xs="auto">
                  <p style={styles.leftColItem}>Date:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>March 2023</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>App Name:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItemRed}>VIP Vegas+</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Platform:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>Android, iOS</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Client:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>VIP Global UK</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Services:</p>
                </Col>
                <Col></Col>
                <Col md="auto">
                  <ul
                    style={{ ...styles.rightColItem, ...{ textAlign: "left" } }}
                  >
                    <li>Mobile App Development</li>
                    <li>Web Services - API</li>
                    <li>MySQL Database</li>
                    <li>Custom CMS Development</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <Row>
                <a
                  href="https://vipglobaluk.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image fluid src={vguLogo} style={styles.vguLogo} />
                </a>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        style={{ ...styles.container, ...{ backgroundColor: "#1C1816" } }}
      >
        <Container>
          <Row className="pb-5">
            <Col>
              <Row className="d-flex justify-content-evenly">
                <Col sd={12} md={3} lg={3} style={styles.mediaCol}>
                  <video
                    style={styles.videoStyle}
                    controls
                    src={vipvegasplus_promo_video}
                  />
                </Col>
                <Col xs={12} md={3} style={styles.mediaCol}>
                  <Image fluid src={vvp_1} style={styles.vpp_images} />
                </Col>
                <Col xs={12} md={3} style={styles.mediaCol}>
                  <Image fluid src={vvp_2} style={styles.vpp_images} />
                </Col>
                <Col xs={12} md={3} style={styles.mediaCol}>
                  <Image fluid src={vvp_3} style={styles.vpp_images} />
                </Col>
              </Row>
              <Row className="mt-5 d-flex justify-content-evenly">
                <Col>
                  <a
                    href="https://apps.apple.com/us/app/vip-vegas/id6444873810"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid
                      src={appStoreImage}
                      style={styles.imageAppStore}
                    />
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.crossvisiondevstudios.vipvegas&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid
                      src={playStoreImage}
                      style={styles.imageGooglePlay}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={styles.container}>
        <Container>
          <Row className="mb-5">
            <Col xs={12} md={6}>
              <Row className="mb-4">
                <h2 style={styles.title}>Web Development</h2>
              </Row>
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Date:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>September 2022</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Client:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItemRed}>Right-Hand Roofing</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Services:</p>
                </Col>
                <Col></Col>
                <Col md="auto">
                  <ul
                    style={{ ...styles.rightColItem, ...{ textAlign: "left" } }}
                  >
                    <li>Web Development</li>
                    <li>Web Hosting</li>
                    <li>Web Maintenance</li>
                  </ul>
                </Col>
              </Row>
              <div className="divider" />
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <Row>
                <a
                  href="https://www.right-handroofing.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image fluid src={rhrLogo} style={styles.rhrLogo} />
                </a>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs={12} md={6}>
              <Row className="mb-4">
                <h2 style={styles.title}>Web Development</h2>
              </Row>
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Date:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>August 2022</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Client:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItemRed}>
                    A1 Property Inspections of Georgia
                  </p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Services:</p>
                </Col>
                <Col></Col>
                <Col md="auto">
                  <ul
                    style={{ ...styles.rightColItem, ...{ textAlign: "left" } }}
                  >
                    <li>Web Hosting</li>
                    <li>Web Maintenance</li>
                  </ul>
                </Col>
              </Row>
              <div className="divider" />
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <Row>
                <a
                  href="https://a1propertyinspectga.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image fluid src={a1pLogo} style={styles.a1pLogo} />
                </a>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Row className="mb-4">
                <h2 style={styles.title}>Web Development</h2>
              </Row>
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Date:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItem}>2019</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Client:</p>
                </Col>
                <Col>
                  <p style={styles.rightColItemRed}>
                    Johnson Global Publishing Group
                  </p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs="auto">
                  <p style={styles.leftColItem}>Services:</p>
                </Col>
                <Col></Col>
                <Col md="auto">
                  <ul
                    style={{ ...styles.rightColItem, ...{ textAlign: "left" } }}
                  >
                    <li>Web Development</li>
                    <li>Web Hosting</li>
                    <li>Web Maintenance</li>
                  </ul>
                </Col>
              </Row>
              <div className="divider" />
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <Row>
                <a
                  href="http://selisalynjohnsonbooks.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image fluid src={jgpgLogo} style={styles.jgpgLogo} />
                </a>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Portfolio;

const styles = {
  a1pLogo: {
    width: 150,
  },

  card: {
    minHeight: 414,
  },
  container: {
    backgroundColor: "#000",
    paddingTop: 150,
    paddingBottom: 150,
  },
  divider: {
    color: "lightGrey",
    height: 2,
    marginTop: 5,
    marginBottom: 5,
  },
  imageAppStore: {
    height: isMobile ? 70 : 100,
    marginTop: isMobile ? 16 : 24,
  },
  imageGooglePlay: {
    height: isMobile ? 104 : 150,
  },
  jgpgLogo: {
    width: 280,
  },
  leftColItem: {
    color: "#FFF",
    fontSize: 20,
    textAlign: "left",
  },
  mediaCol: {
    marginBottom: isMobile ? 20 : 0,
  },
  rhrLogo: {
    width: 200,
  },
  rightColItem: {
    color: "#ACACAC",
    fontSize: 20,
    textAlign: "right",
  },
  rightColItemRed: {
    color: "#CF3620",
    fontSize: 20,
    textAlign: "right",
  },
  title: {
    color: "#FFF",
    textAlign: isMobile ? "center" : "left",
  },
  vguLogo: {
    width: 300,
  },
  vpp_images: {
    width: 225,
  },
  videoStyle: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
};
