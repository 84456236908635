import axios from "axios";

const getConfig = () => {
  const config = {
    headers: {
      apiSecret: "6CVDS#kIcj_!7e",
    },
  };
  return config;
};

const BaseApiPath = (qtype) => {
  const API_PATH =
    "https://cws.crossvisiondevstudios.com/php/ccms/contentservice.php";
  return `${API_PATH}?qtype=${qtype}`;
};

const postAddBusinessInquiries = (body, callback) => {
  const url = `${BaseApiPath("businessinquiry")}`;
  postUrlSession(url, body, callback);
};

const postMail = (body, callback) => {
  const url = "https://cws.crossvisiondevstudios.com/mail";
  postUrlSession(url, body, callback);
};

const postUrlSession = (url, body, callback) => {
  axios
    .post(url, body, getConfig())
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

export { postMail, postAddBusinessInquiries };
