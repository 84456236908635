import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import MainBody from "../components/layout/MainBody";

const Home = () => {
  const logoImage = require("../assets/logos/cvdsLogo.png");

  return (
    <MainBody>
      <Container style={styles.homeStyle}>
        <Col>
          <Row>
            <Image src={logoImage} width="800"/>
          </Row>
        </Col>
      </Container>
    </MainBody>
  );
};

export default Home;

const styles = {
  homeStyle: {
    height: 600,
    // paddingTop: "9%",
    // paddingBottom: "10%",
  },
};
