import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import MainBody from "../components/layout/MainBody";
import { postAddBusinessInquiries } from "../api/ApiManager";
import { isMobile } from "react-device-detect";

const Contact = () => {
  const captchaRef = useRef(null);
  const defaultStateData = {
    firstname: true,
    lastname: true,
    email: true,
    companyname: true,
    companytype: true,
    projectbudget: true,
    projectsynopsis: true,
    dataUpdated: false,
    content: {
      firstname: "",
      lastname: "",
      email: "",
      companyname: "",
      companytype: "",
      projectbudget: "",
      projectsynopsis: "",
      existingdocumentation: false,
      existingdesign: false,
      existingcode: false,
      howfindus: "",
    },
    expired: false,
    recaptchaLoaded: false,
  };

  const [validationState, setValidationState] = useState(defaultStateData);

  const isValidated =
    validationState.firstname &&
    validationState.lastname &&
    validationState.email &&
    validationState.companyname &&
    validationState.companytype &&
    validationState.projectbudget &&
    validationState.projectsynopsis &&
    validationState.dataUpdated;

  // Handlers
  const handleRecaptchaChange = (value) => {
    if (value === null) {
      setValidationState({ ...validationState, expired: true });
    } else {
    }
  };

  const onChangeHandler = (e) => {
    const { name, value, checked } = e.target;

    let validationValue = false;
    let contentValue = null;

    if (value === "on") {
      validationValue = checked;
      contentValue = checked;
    } else {
      validationValue = value.trim() !== "" && value.trim() !== "Please Select";
      contentValue = value;
    }

    // Validation
    setValidationState((prev) => ({
      ...prev,
      [name]: validationValue,
      dataUpdated: true,
      content: { ...prev.content, [name]: contentValue },
    }));
  };

  const onSubmitHandler = async (e) => {
    Validate();

    if (isValidated) {
      e.preventDefault();

      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      const recaptchaURL = `${process.env.REACT_APP_CVDS_SERVICES_API_URL}/recaptcha`;
      await axios
        .post(recaptchaURL, { token: token })
        .then((res) => {
          if (res.data === "Human 👨 👩") {
            postAddBusinessInquiries(validationState.content, () => {
              // setValidationState(defaultStateData);
              alert("Thank You, Inquiry Sent!");
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Methods
  const asyncScriptOnLoad = () => {
    setValidationState({ ...validationState, recaptchaLoaded: true });
  };

  const Validate = () => {
    let validationObject = {
      dataUpdated: validationState.dataUpdated,
      content: validationState.content,
    };

    validationObject["firstname"] = !validator.isEmpty(
      validationState.content.firstname,
      { ignore_whitespace: true }
    );
    validationObject["lastname"] = !validator.isEmpty(
      validationState.content.lastname,
      { ignore_whitespace: true }
    );
    validationObject["email"] = validator.isEmail(
      validationState.content.email
    );
    validationObject["companyname"] = !validator.isEmpty(
      validationState.content.companyname,
      { ignore_whitespace: true }
    );
    validationObject["companytype"] = !validator.isEmpty(
      validationState.content.companytype,
      { ignore_whitespace: true }
    );
    validationObject["projectbudget"] = !validator.isEmpty(
      validationState.content.projectbudget,
      { ignore_whitespace: true }
    );
    validationObject["projectsynopsis"] = !validator.isEmpty(
      validationState.content.projectsynopsis,
      { ignore_whitespace: true }
    );

    setValidationState(validationObject);
  };

  return (
    <MainBody>
      <Container className="d-flex justify-content-center">
        <Col>
          <Row>
            <p
              style={{
                fontSize: 44,
                fontWeight: "bold",
                color: "#FFF",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              We are excited to work with you!
            </p>
          </Row>
          <Row className="mb-4">
            <p
              style={{
                fontSize: 30,
                fontWeight: "bold",
                color: "#CF3620",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Submit a short brief below to get started.
            </p>
          </Row>
          <Row className="d-flex justify-content-center">
            <Form style={styles.form}>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>First Name</Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Control
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={validationState.content.firstname}
                      onChange={onChangeHandler}
                    />
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.firstname}
                    >
                      Please complete this required field.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>Last Name</Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Control
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={validationState.content.lastname}
                      onChange={onChangeHandler}
                    />
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.lastname}
                    >
                      Please complete this required field.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>Email</Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      value={validationState.content.email}
                      onChange={onChangeHandler}
                    />
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.email}
                    >
                      Please complete this required field.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>Company Name</Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Control
                      type="text"
                      id="companyname"
                      name="companyname"
                      value={validationState.content.companyname}
                      onChange={onChangeHandler}
                    />
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.companyname}
                    >
                      Please complete this required field.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>Company Type</Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Select
                      aria-label="Default select company type"
                      id="companytype"
                      name="companytype"
                      value={validationState.content.companytype}
                      onChange={onChangeHandler}
                    >
                      <option>Please Select</option>
                      <option value="Idea">Idea</option>
                      <option value="Startup">Startup</option>
                      <option value="Small or Midsize Business">
                        Small or Midsize Business
                      </option>
                      <option value="Enterprise">Enterprise</option>
                      <option value="Government">Government</option>
                    </Form.Select>
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.companytype}
                    >
                      Please select an option from the dropdown menu.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>
                      Total Project Budget
                    </Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Select
                      aria-label="Default select project budget"
                      id="projectbudget"
                      name="projectbudget"
                      value={validationState.content.projectbudget}
                      onChange={onChangeHandler}
                    >
                      <option>Please Select</option>
                      <option value="Under $5,000">Under $5,000</option>
                      <option value="$5,000 - $15,000">$5,000 - $15,000</option>
                      <option value="$15,000 - $25,000">
                        $15,000 - $25,000
                      </option>
                      <option value="$25,000 - $50,000">
                        $25,000 - $50,000
                      </option>
                      <option value="$50,000+">$50,000+</option>
                    </Form.Select>
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.projectbudget}
                    >
                      Please select an option from the dropdown menu.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>
                      Project Synopsis
                    </Form.Label>
                    <Form.Label style={styles.asterisk}>*</Form.Label>
                    <Form.Control
                      type="text"
                      id="projectsynopsis"
                      name="projectsynopsis"
                      value={validationState.content.projectsynopsis}
                      onChange={onChangeHandler}
                    />
                    <Form.Text
                      style={styles.error}
                      hidden={validationState.projectsynopsis}
                    >
                      Please complete this required field.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="px-3">
                  <Form.Check
                    type="checkbox"
                    id="existingdocumentation"
                    name="existingdocumentation"
                    checked={validationState.content.existingdocumentation}
                    label="I have existing documentation"
                    style={styles.checkbox}
                    onChange={onChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="px-3">
                  <Form.Check
                    type="checkbox"
                    id="existingdesign"
                    name="existingdesign"
                    checked={validationState.content.existingdesign}
                    label="I have existing designs"
                    style={styles.checkbox}
                    onChange={onChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Check
                    type="checkbox"
                    id="existingcode"
                    name="existingcode"
                    checked={validationState.content.existingcode}
                    label="I have an existing code base"
                    style={styles.checkbox}
                    onChange={onChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="px-3">
                  <Form.Group>
                    <Form.Label style={styles.label}>
                      How did you find us?
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select how did you find us"
                      id="howfindus"
                      name="howfindus"
                      value={validationState.content.howfindus}
                      onChange={onChangeHandler}
                    >
                      <option>Please Select</option>
                      <option value="Google">Google</option>
                      <option value="Word of Mouth">Word of Mouth</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <ReCAPTCHA
                  className="mb-3"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                  ref={captchaRef}
                  asyncScriptOnLoad={asyncScriptOnLoad}
                />
              </Row>
              <Row>
                <Col className="d-flex justify-content-end px-3">
                  <Button
                    variant="primary"
                    style={styles.button}
                    onClick={onSubmitHandler}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Container>
    </MainBody>
  );
};

export default Contact;

const styles = {
  asterisk: {
    fontSize: 16,
    fontWeight: "bold",
    color: "red",
    textAlign: "left",
    paddingLeft: 0,
    marginBottom: 0,
  },
  button: {
    width: 120,
  },
  checkbox: {
    color: "#FFF",
    textAlign: "left",
  },
  error: {
    color: "red",
  },
  label: {
    fontSize: isMobile ? 14: 16,
    color: "#FFF",
    textAlign: "left",
    paddingLeft: 0,
    marginBottom: 0,
  },
  form: {
    width: isMobile ? "100%" : 700,
    textAlign: "left",
  },
};
